import React from 'react'
import Layout from '../components/layouts/Layout'

const Analytics = () => {
  return (
    <Layout>
        
    </Layout>
  )
}

export default Analytics