const countriesWcode = {
  "+93": "Afghanistan",
  "+358": "Finland",
  "+355": "Albania",
  "+213": "Algeria",
  "+1684": "AmericanSamoa",
  "+376": "Andorra",
  "+244": "Angola",
  "+1264": "Anguilla",
  "+672": "Norfolk Island",
  "+1268": "Antigua and Barbuda",
  "+54": "Argentina",
  "+374": "Armenia",
  "+297": "Aruba",
  "+61": "Cocos (Keeling) Islands",
  "+43": "Austria",
  "+994": "Azerbaijan",
  "+1242": "Bahamas",
  "+973": "Bahrain",
  "+880": "Bangladesh",
  "+1246": "Barbados",
  "+375": "Belarus",
  "+32": "Belgium",
  "+501": "Belize",
  "+229": "Benin",
  "+1441": "Bermuda",
  "+975": "Bhutan",
  "+591": "Bolivia",
  "+387": "Bosnia and Herzegovina",
  "+267": "Botswana",
  "+55": "Brazil",
  "+246": "British Indian Ocean Territory",
  "+673": "Brunei Darussalam",
  "+359": "Bulgaria",
  "+226": "Burkina Faso",
  "+257": "Burundi",
  "+855": "Cambodia",
  "+237": "Cameroon",
  "+1": "United States",
  "+238": "Cape Verde",
  "+ 345": "Cayman Islands",
  "+236": "Central African Republic",
  "+235": "Chad",
  "+56": "Chile",
  "+86": "China",
  "+57": "Colombia",
  "+269": "Comoros",
  "+242": "Congo",
  "+243": "Congo",
  "+682": "Cook Islands",
  "+506": "Costa Rica",
  "+225": "Cote d'Ivoire",
  "+385": "Croatia",
  "+53": "Cuba",
  "+357": "Cyprus",
  "+420": "Czech Republic",
  "+45": "Denmark",
  "+253": "Djibouti",
  "+1767": "Dominica",
  "+1849": "Dominican Republic",
  "+593": "Ecuador",
  "+20": "Egypt",
  "+503": "El Salvador",
  "+240": "Equatorial Guinea",
  "+291": "Eritrea",
  "+372": "Estonia",
  "+251": "Ethiopia",
  "+500": "South Georgia and the South Sandwich Islands",
  "+298": "Faroe Islands",
  "+679": "Fiji",
  "+33": "France",
  "+594": "French Guiana",
  "+689": "French Polynesia",
  "+241": "Gabon",
  "+220": "Gambia",
  "+995": "Georgia",
  "+49": "Germany",
  "+233": "Ghana",
  "+350": "Gibraltar",
  "+30": "Greece",
  "+299": "Greenland",
  "+1473": "Grenada",
  "+590": "Saint Martin",
  "+1671": "Guam",
  "+502": "Guatemala",
  "+44": "United Kingdom",
  "+224": "Guinea",
  "+245": "Guinea-Bissau",
  "+595": "Paraguay",
  "+509": "Haiti",
  "+379": "Holy See (Vatican City State)",
  "+504": "Honduras",
  "+852": "Hong Kong",
  "+36": "Hungary",
  "+354": "Iceland",
  "+91": "India",
  "+62": "Indonesia",
  "+98": "Iran",
  "+964": "Iraq",
  "+353": "Ireland",
  "+972": "Israel",
  "+39": "Italy",
  "+1876": "Jamaica",
  "+81": "Japan",
  "+962": "Jordan",
  "+77": "Kazakhstan",
  "+254": "Kenya",
  "+686": "Kiribati",
  "+850": "Korea, Democratic People's Republic of Korea",
  "+82": "Korea, Republic of South Korea",
  "+965": "Kuwait",
  "+996": "Kyrgyzstan",
  "+856": "Laos",
  "+371": "Latvia",
  "+961": "Lebanon",
  "+266": "Lesotho",
  "+231": "Liberia",
  "+218": "Libyan Arab Jamahiriya",
  "+423": "Liechtenstein",
  "+370": "Lithuania",
  "+352": "Luxembourg",
  "+853": "Macao",
  "+389": "Macedonia",
  "+261": "Madagascar",
  "+265": "Malawi",
  "+60": "Malaysia",
  "+960": "Maldives",
  "+223": "Mali",
  "+356": "Malta",
  "+692": "Marshall Islands",
  "+596": "Martinique",
  "+222": "Mauritania",
  "+230": "Mauritius",
  "+262": "Reunion",
  "+52": "Mexico",
  "+691": "Micronesia",
  "+373": "Moldova",
  "+377": "Monaco",
  "+976": "Mongolia",
  "+382": "Montenegro",
  "+1664": "Montserrat",
  "+212": "Morocco",
  "+258": "Mozambique",
  "+95": "Myanmar",
  "+264": "Namibia",
  "+674": "Nauru",
  "+977": "Nepal",
  "+31": "Netherlands",
  "+599": "Netherlands Antilles",
  "+687": "New Caledonia",
  "+64": "New Zealand",
  "+505": "Nicaragua",
  "+227": "Niger",
  "+234": "Nigeria",
  "+683": "Niue",
  "+1670": "Northern Mariana Islands",
  "+47": "Svalbard and Jan Mayen",
  "+968": "Oman",
  "+92": "Pakistan",
  "+680": "Palau",
  "+970": "Palestinian Territory, Occupied",
  "+507": "Panama",
  "+675": "Papua New Guinea",
  "+51": "Peru",
  "+63": "Philippines",
  "+872": "Pitcairn",
  "+48": "Poland",
  "+351": "Portugal",
  "+1939": "Puerto Rico",
  "+974": "Qatar",
  "+40": "Romania",
  "+7": "Russia",
  "+250": "Rwanda",
  "+290": "Saint Helena",
  "+1869": "Saint Kitts and Nevis",
  "+1758": "Saint Lucia",
  "+508": "Saint Pierre and Miquelon",
  "+1784": "Saint Vincent and the Grenadines",
  "+685": "Samoa",
  "+378": "San Marino",
  "+239": "Sao Tome and Principe",
  "+966": "Saudi Arabia",
  "+221": "Senegal",
  "+381": "Serbia",
  "+248": "Seychelles",
  "+232": "Sierra Leone",
  "+65": "Singapore",
  "+421": "Slovakia",
  "+386": "Slovenia",
  "+677": "Solomon Islands",
  "+252": "Somalia",
  "+27": "South Africa",
  "+211": "South Sudan",
  "+34": "Spain",
  "+94": "Sri Lanka",
  "+249": "Sudan",
  "+597": "Suriname",
  "+268": "Swaziland",
  "+46": "Sweden",
  "+41": "Switzerland",
  "+963": "Syrian Arab Republic",
  "+886": "Taiwan",
  "+992": "Tajikistan",
  "+255": "Tanzania",
  "+66": "Thailand",
  "+670": "Timor-Leste",
  "+228": "Togo",
  "+690": "Tokelau",
  "+676": "Tonga",
  "+1868": "Trinidad and Tobago",
  "+216": "Tunisia",
  "+90": "Turkey",
  "+993": "Turkmenistan",
  "+1649": "Turks and Caicos Islands",
  "+688": "Tuvalu",
  "+256": "Uganda",
  "+380": "Ukraine",
  "+971": "United Arab Emirates",
  "+598": "Uruguay",
  "+998": "Uzbekistan",
  "+678": "Vanuatu",
  "+58": "Venezuela",
  "+84": "Vietnam",
  "+1284": "Virgin Islands, British",
  "+1340": "Virgin Islands, U.S.",
  "+681": "Wallis and Futuna",
  "+967": "Yemen",
  "+260": "Zambia",
  "+263": "Zimbabwe",
};

export default countriesWcode;
