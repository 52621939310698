import React from 'react'
import Modal from "react-bootstrap/Modal";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useEffect } from 'react';
import { useState } from 'react';
const TrackModal = ({ viewVal, setShowVal,reqDetail}) => {
    const [trackStatus,setTrackStatus]=useState()
    useEffect(()=>{
if(reqDetail?.complete_drive_status==10){
    setTrackStatus(4)
}else if(reqDetail?.drive_to_pickup_status==9){
    setTrackStatus(3)
  }
  else if(reqDetail?.arrive_to_pickup_status==8){
    setTrackStatus(2)
  }
  else if(reqDetail?.drive_to_pickup_status==7){
    setTrackStatus(1)
  }
    },[reqDetail])
    const handleClose = () => {
        setShowVal(false);
      };
      const steps = [
        'Drive To Pick-up',
        'Arrive at Pick-up',
        'Arrived at Drop-Off',
        "Complete Drive"
      ];
  return (
    <Modal
    show={viewVal}
    onHide={handleClose}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="delete_account_modal"
  >
    <Modal.Header closeButton className='modal_header'>
      {/* <Modal.Title id="contained-modal-title-vcenter">
      Tracking
      </Modal.Title> */}
    </Modal.Header>
    <Modal.Body>
      <h4 className="text-center mb-0 delete_content">
        
      <Stepper activeStep={
       trackStatus
        
      } alternativeLabel>
  {steps.map((label) => (
    <Step key={label}>
      <StepLabel>{label}</StepLabel>
    </Step>
  ))}
</Stepper>

      </h4>
      <div className="d-flex justify-content-center delete_acc_btns">
        
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default TrackModal