import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
/// Image
import profile from "../../images/profile/profile.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  driversFetch,
  requestDetail,
  updateBookingAmount,
} from "../../store/actions/AdminAction";
import {useParams } from "react-router-dom";
import { useState } from "react";
import LicenceImg from "../../images/profile/license-placeholder.png";
import { toast } from "react-toastify";
import TrackModal from "../components/modals/TrackModal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const RequestDetail = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [amount, setAmount] = useState(" ");
  const [driverName, setDriverName] = useState();
  const [viewTrack, setViewTrack] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const reqDetail = useSelector((state) => state?.adminReducer?.reqDetail);
  console.log(reqDetail,"reqDetailreqDetail");
  const allDrivers = useSelector((state) => state?.adminReducer?.driverData);
  useEffect(() => {
    dispatch(requestDetail(id));
    dispatch(driversFetch());
  }, []);

  useEffect(() => {
    if (reqDetail?.amount) {
     setAmount(reqDetail?.amount);
    }else{
      setAmount(" ")
    }
    setDriverName(reqDetail?.driverId?.full_name)
   
  }, [reqDetail]);
  const handleSelect = (driverId) => {
    if(driverId){
      dispatch(
        updateBookingAmount({
          bookingId: reqDetail?._id,
          driverId: driverId,
          status:4
        })
      ).then((data)=>{
        console.log(data,"amount");
        dispatch(requestDetail(id));
      })
    }
  };
  // arrive_to_dropoff_status
  // arrive_to_pickup_status
  // drive_to_pickup_status
  // complete_drive_status
  
  return (
    <Layout>
      {viewTrack == true && (
          <TrackModal viewVal={viewTrack} setShowVal={setViewTrack} reqDetail={reqDetail}/>
        )}
      <div>
        <MainPagetitle
          mainTitle="Request Detail"
          pageTitle={"Request Detail"}
          parentTitle={"Home"}
        />
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="profile card card-body ">
              <div className="card-body ">
                <div className="row align_center">
                  <div className="col-xl-2 col-lg-3">
                    <img
                      src={
                        reqDetail?.booking_photo
                          ? IMG_URL + reqDetail?.booking_photo
                          : LicenceImg
                      }
                      className="img-fluid profile-image"
                      alt="profile"
                    />
                  </div>
                  <div className="col-xl-10 col-lg-9">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="c-card-detail">
                          <ul>
                            <li>
                              <h6>Customer Name</h6>
                              <p>
                                {reqDetail?.userId?.full_name
                                  ? reqDetail?.userId?.full_name
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h6>Category Name</h6>
                              <p>
                                {reqDetail?.booking_category == 1
                                  ? "Construction & Building"
                                  : reqDetail?.booking_category == 2
                                  ? "Home Furniture & Decor"
                                  : reqDetail?.booking_category == 3
                                  ? "Other Materials"
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="c-card-details">
                          <ul>
                            <li className="amount_align">
                              <h6>Amount:</h6>
                              <div className="form-group col-md-6">
                              {
                               [0,1,2].includes(reqDetail?.status)?(<input
                                  type="number"
                                  name="amount"
                                  className="ms-1 form-control"
                                  placeholder="Enter Amount"
                                  value={amount&& amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />):(<input
                                  type="number"
                                  name="amount"
                                  className="ms-1 form-control not-allowed"
                                  placeholder="Enter Amount"
                                  value={amount}
                                  // onChange={(e) => setAmount(e.target.value)}
                                  disabled
                                />)
                              }
                                
                              </div>
                              {
                                
                               ( [0,1,2].includes(reqDetail?.status)?(<button
                                onClick={() =>
                                  dispatch(
                                    updateBookingAmount({
                                      bookingId: reqDetail?._id,
                                      amount: amount,
                                      status:1
                                    })
                                  ).then((data)=>{
                                    console.log(data,"amount");
                                    dispatch(requestDetail(id));
                                  })
                                }
                                className="ms-4 btn btn-primary col-md-3"
                              >
                                {reqDetail?.amount ? "Update" : "Add"}
                              </button>):(<button
                                className="ms-4 btn btn-primary col-md-3 not-allowed"
                                disabled
                              >
                                {reqDetail?.amount ? "Updated" : "Add"}
                              </button> ))
                              }
                             
                            </li>

                            <li className="driver_select mt-4">
                              <h6>Driver:</h6>
                              {
                                [3,4,5,6]?.includes(reqDetail?.status) ? (<select
                                defaultValue={"option"}
                                id="inputState"
                                className="ms-1 select-allow"
                                onChange={(e) => handleSelect(e.target.value)}
                              >
                                <option value="option" disabled hidden>
                                  {driverName ? driverName : "Select"}
                                </option>
                                {allDrivers?.map((item) => {
                                  return (
                                    <option value={item?._id}>
                                      {item?.full_name}
                                    </option>
                                  );
                                })}
                              </select>):(
                                <select
                                defaultValue={"option"}
                                id="inputState"
                                className="ms-1 select-allow not-allowed"
                                disabled 
                              >
                                <option value="option" disabled hidden>
                                 Select...
                                </option>
                                
                              </select>
                              )
                              }
                              
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Booking Info</h4>
                </div>
                <div className="card-body">
                  <div className="form-validation">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="booking_info">
                          <h6>Type :</h6>
                          <p>
                            {reqDetail?.booking_type
                              ? reqDetail?.booking_type
                              : "N/A"}
                          </p>
                        </div>
                        <div className="booking_info">
                          <h6>Max Length :</h6>
                          <p>{reqDetail?.length ? reqDetail?.length : "N/A"}</p>
                        </div>
                        <div className="booking_info">
                          <h6>Max Width :</h6>
                          <p>{reqDetail?.width ? reqDetail?.width : "N/A"}</p>
                        </div>
                        <div className="booking_info">
                          <h6>Estimated Weight :</h6>
                          <p>{reqDetail?.weight ? reqDetail?.weight : "N/A"}</p>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="booking_info">
                          <h6>Quantity :</h6>
                          <p>{reqDetail?.qty ? reqDetail?.qty : "N/A"}</p>
                        </div>
                        <div className="booking_info">
                          <h6>Quantity Items :</h6>
                          <p>
                            {reqDetail?.qty_items
                              ? reqDetail?.qty_items
                              : "N/A"}
                          </p>
                        </div>
                        <div className="booking_info">
                          <h6>Will Assist in Uploading :</h6>
                          <p>
                            {reqDetail?.assist_in_unloading == 0
                              ? "No"
                              : reqDetail?.assist_in_unloading == 1
                              ? "Yes"
                              : "N/A"}
                          </p>
                        </div>
                        <div className="booking_info">
                        <h6>Track :</h6>
                        </div>
                        <div className="">
                    
                          <button
                            onClick={() => setViewTrack(true)}
                            className="btn btn-primary"
                          >
                            Track
                          </button>
                        </div>
                        {/* <div className="booking_info">
                          <h6>Reviews :</h6>
                          <p>
                            {reqDetail?.rating?
                              reqDetail?.rating
                              : "N/A"}
                          </p>
                        </div> */}
                      </div>
                      <div className="col-xl-12">
                        <div className="booking_info">
                          <h6>Discription :</h6>
                          <p>
                            {reqDetail?.details?reqDetail?.details:"N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Booking Address</h4>
                </div>
                <div className="card-body">
                  <div className="form-validation">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="booking_info">
                          <h6>Date of Pickup :</h6>
                          <p>
                            {reqDetail?.date_of_pickup
                              ? reqDetail?.date_of_pickup
                              : "N/A"}
                          </p>
                        </div>

                        <div className="booking_info">
                          <h6>Time of Pickup :</h6>
                          <p>
                            {reqDetail?.time_of_pickup
                              ? reqDetail?.time_of_pickup
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="booking_info">
                          <h6>Pick-up Address :</h6>
                          <p>
                            {reqDetail?.pickup_address
                              ? reqDetail?.pickup_address
                              : "N/A"}
                          </p>
                        </div>

                        <div className="booking_info">
                          <h6>Drop-off Address :</h6>
                          <p>
                            {reqDetail?.dropoff_address
                              ? reqDetail?.dropoff_address
                              : "N/A"}
                          </p>
                        </div>
                        <div className="booking_info">
                          <h6>Are the items ready for pickup?</h6>
                          <p>
                            {reqDetail?.ready_for_pickup == 1 ? (
                              <span class="badge light border-0 badge-success">
                                Yes
                              </span>
                            ) : (
                              <span class="badge light border-0 badge-danger">
                                No
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RequestDetail;
