import React, { useState } from 'react'
import { Children } from 'react'
import NavHader from './nav/NavHader'
import Header from './nav/Header'
import SideBar from './nav/SideBar'
import Footer from './Footer'
import { useSelector } from 'react-redux'

const Layout = (props) => {
    const [toggle, setToggle] = useState("");
    const onClick = (name) => setToggle(toggle === name ? "" : name);
    const sideMenu = useSelector(state => state.sideMenu);
  return (
     <>
        <div id="main-wrapper" className={`show ${ sideMenu ? "menu-toggle" : ""}`}>  
        <NavHader />
        <Header
            onNote={() => onClick("chatbox")}
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            // title={title}
            onBox={() => onClick("box")}
            // onClick={() => ClickToAddEvent()}
            
          /> 
          <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>          
         
          {props.children} 
          </div>
        <SideBar />	
        <Footer />
      </div>
       
     </>
  )
}

export default Layout