import React from 'react';

import DepositlineChart from './DepositlineChart';
import AllProjectDonutChart from './AllProjectDonutChart';
import { SVGICON } from '../../constant/theme';

const CardWidget = ({dashboard}) => {
    console.log(dashboard,"dashboard");
    return (
        <>
            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>Total Drivers</h6>
                                <h3>{dashboard?.totalDrivers?dashboard?.totalDrivers:"N/A"}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.User}
                            </div>
                        </div>                        
                        <DepositlineChart chartcolor="var(--primary)" />
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>Verified Deivers</h6>
                                <h3>{dashboard?.verifiedDrivers?dashboard?.verifiedDrivers:"N/A"}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.Performance}
                            </div>
                        </div>
                        <DepositlineChart chartcolor="#FF5E5E" />
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>New Driver Registration</h6>
                                <h3>{dashboard?.newRegistrationDrivers?dashboard?.newRegistrationDrivers:"N/A"}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                            {SVGICON.CalendarList}
                            </div>
                        </div>                        
                        <DepositlineChart chartcolor="var(--primary)" />
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>Total Company</h6>
                                <h3>{dashboard?.totalUsers?dashboard?.totalUsers:"N/A"}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.User}
                            </div>
                        </div>                        
                        <DepositlineChart chartcolor="var(--primary)" />
                    </div>
                </div>
            </div>
         
            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>Verified Company</h6>
                                <h3>{dashboard?.verifiedUsers?dashboard?.verifiedUsers:"N/A"}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.Performance}
                            </div>
                        </div>
                        <DepositlineChart chartcolor="#FF5E5E" />
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>New Company Registration</h6>
                                <h3>{dashboard?.newRegistration?dashboard?.newRegistration:"N/A"}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                            {SVGICON.CalendarList}
                            </div>
                        </div>                        
                        <DepositlineChart chartcolor="var(--primary)" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardWidget;