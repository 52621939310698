import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { navtoggle } from "../../../../store/actions/AuthActions";
// import LogoWhite2 from "../../../../images/logo/logo_main.svg";
import LogoWhite2 from "../../../../images/logo/app_icon.png";

const NavHader = () => {
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        <img
          src={LogoWhite2}
          className="theme-logo-o logo-abbr"
          style={{ width: "38px", height: "38px", viewBox: "0 0 47 16" }}
        />
        <h4
          className="theme-logo-o brand-title"
          style={{
            width: "78px",
            height: "30px",
            viewBox: "0 0 47 16",
            color: "white",
            fontSize: "x-large",
          }}
        >
          RIGGIGS
        </h4>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
