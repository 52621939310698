import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { modalBody, modalTitle } from "./ModalData";
import { useNavigate } from "react-router-dom";
import {
  deleteDriverAcc,
  deleteUserAcc,
  updateDriverProfile,
  updateUserProfile,
  viewDriverDetail,
  viewUserDetail,
} from "../../../store/actions/AdminAction";
const DeleteModal = ({ viewVal, setShowVal, modalName, id, driverId,userId }) => {
  console.log(modalName,"modalName");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowVal(false);
  };

  const handleDelete = () => {
    switch (modalName) {
      case "userAccount":
        dispatch(deleteUserAcc(id, navigate));
        setShowVal(false);
        break;
      case "driverAccount":
        dispatch(deleteDriverAcc(id, navigate));
        setShowVal(false);
        break;
      case "deactiveAccount":
        dispatch(
          updateDriverProfile({
            driverId: driverId,
            status: 1,
          })
        ).then((res)=>{
          if(res==undefined){
            dispatch(viewDriverDetail(driverId));
          }
        });
        setShowVal(false);
        break;
      case "activateAccount":
        dispatch(
          updateDriverProfile({
            driverId: driverId,
            status: 0,
          })
        ).then((res)=>{
          if(res==undefined){
            dispatch(viewDriverDetail(driverId));
          }
        });
        setShowVal(false);
        break;
        case "deactiveUser":
        dispatch(
          updateUserProfile({
            userId: userId,
            status: 1,
          })
        ).then((res)=>{
          if(res){
            dispatch(viewUserDetail(userId));
          }
        });
        setShowVal(false);
        break;
      case "activateUser":
        dispatch(
          updateUserProfile({
            userId: userId,
            status: 0,
          })
        ).then((res)=>{
          if(res){
            dispatch(viewUserDetail(userId));
          }
        });
        setShowVal(false);
        break;
    }
  }; 
  return (
    <Modal
      show={viewVal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete_account_modal"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle[modalName]}
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <h4 className="text-center mb-0 delete_content">
          {modalBody[modalName]}
        </h4>
        <div className="d-flex justify-content-center delete_acc_btns">
          <button
            onClick={handleDelete}
            type="submit"
            className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
          >
            Yes
          </button>
          <button
            onClick={handleClose}
            type="submit"
            className="btn btn-primary btn-custom btn-lg submit_btn confirmation_btn"
          >
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;