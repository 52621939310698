import React from 'react'
import { useSelector } from "react-redux";
import { FadeLoader } from 'react-spinners';
const Loader = () => {
    const isLoading = useSelector((state) => state?.loderReducer?.loading);
  return (
  <>
{isLoading &&
        (
          <div className="loader-style container-fluid">
            <FadeLoader
              className="fade-loader"
              loading={isLoading}
              color={"#2E4BAA"}
            />
          </div>
        )}
  </>
    
  )
}

export default Loader