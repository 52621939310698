import React from 'react'
import { useState } from 'react';

const Search = ({setSearch,setPage}) => {
    const [searchData, setSearchData] = useState("");
    const handleKeyEnter = (e) => {
      if (e.key == "Enter") {
          setSearch(searchData);
          setPage(1)
      }
  }
  const handleSearch =(str)=>{
    function onlyLettersAndSpaces(str) {
      return /^[A-Za-z0-9\s]*$/.test(str);
    }

    if (onlyLettersAndSpaces(str)){
        setSearchData(str)
    }
   
}
 
    // const handleSearch = (e) => {
    //    if (e.key == "Enter") {
    //     function onlyLettersAndSpaces(str) {
    //       return /^[A-Za-z0-9\s]*$/.test(str);
    //     }

    //     if(onlyLettersAndSpaces(e)){
    //       setSearch(e);
    //       setSearchData(e)
    //       setPage(1);
    //     }
    
    //    }
    //   };
    
 
  return (
    <div className="">
    <input
      value={searchData?.trimStart()}
      type="text"
      className="form-control rounded-1"
      placeholder="Search"
      onChange={(e) => handleSearch(e.target.value)}
     onKeyDown={(e) => handleKeyEnter(e)}
    />
  </div>
  )
}

export default Search