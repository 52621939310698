import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import LightGallery from "lightgallery/react";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LanguageIcon from "@mui/icons-material/Language";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import InfoIcon from '@mui/icons-material/Info';
/// Image
import profile from "../../images/profile/profile.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordDriver,
  deleteDriverAcc,
  updateDriverProfile,
  viewDriverDetail,
} from "../../store/actions/AdminAction";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Button, div } from "@mui/material";
import { IMAGES } from "../constant/theme";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LicenceImg from "../../images/profile/license-placeholder.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import DeleteModal from "../components/modals/DeleteModal";
import Switch from "@mui/material/Switch";
import CustomStyles from "../../utils/SelectStyle";
import countriesCodeSelect from "../constant/CountryCodeSelect";
import Select from "react-select";
const label = { inputProps: { "aria-label": "Size switch demo" } };
const DriverDetail = () => {
  const countryCode = countriesCodeSelect;
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [profileImage, setProfileImage] = useState("");
  const [preview, setPreview] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [driverId, setDriverId] = useState();
  const [viewVal, setViewVal] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [activate, setActivate] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const driverDetail = useSelector(
    (state) => state?.adminReducer?.driverDetail
  );
  const themeMode = useSelector((state) => state.adminReducer.themeMode);
  const customStyles = CustomStyles(themeMode);
  useEffect(() => {
    dispatch(viewDriverDetail(id));
  }, []);
  useEffect(() => {
    if (driverDetail) {
      setProfileImage(driverDetail?.profile_image);
      setFullName(driverDetail?.full_name);
      setEmail(driverDetail?.email);
      setPhoneNumber(driverDetail?.phone_number);
      setStatus(driverDetail?.status);
      setSwitchStatus(driverDetail?.status == 0 ? true : false);
      setSelectedCode({
        label: driverDetail?.country_code,
        value: driverDetail?.country_code,
      });
    }
  }, [driverDetail]);
  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("driverId", driverDetail?._id);
    profileImage && formData.append("profile_image", profileImage);
    fullName && formData.append("full_name", fullName);
    email && formData.append("email", email);
    phoneNumber && formData.append("phone_number", phoneNumber);
    status && formData.append("status", status);
    if (fullName && phoneNumber && email) {
    dispatch(updateDriverProfile(formData));
    }
  };
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangePass = () => {
    const dataVal = {
      userId: driverDetail?._id,
      password: password,
    };
    if (password) {
      if (password == confirmPassword) {
        dispatch(changePasswordDriver(dataVal));
      } else {
        toast.error("Password dosen't match");
      }
    }
  };
  const handleDeactivateAcc = (checked) => {
    if (checked == true) {
      setDriverId(driverDetail?._id);
      setActivate(true);
    } else if (checked == false) {
      setDriverId(driverDetail?._id);
      setDeactivate(true);
    }
  };
 
  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Driver Details & Edit"
          pageTitle={"Driver Details"}
          parentTitle={"Home"}
        />
        {deleteId && ( 
          <DeleteModal
            viewVal={viewVal}
            setShowVal={setViewVal}
            modalName={"driverAccount"}
            id={deleteId}
          />
        )}
        {deactivate == true && (
          <DeleteModal
            viewVal={deactivate}
            setShowVal={setDeactivate}
            modalName={"deactiveAccount"}
            driverId={driverId}
          />
        )}
        {activate == true && (
          <DeleteModal
            viewVal={activate}
            setShowVal={setActivate}
            modalName={"activateAccount"}
            driverId={driverId}
          />
        )}
        {/* {viewTrack == true && (
          <TrackModal viewVal={viewTrack} setShowVal={setViewTrack} />
        )} */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info">
                    <div className="user_detail_img_inner ">
                      {preview ? (
                        <img
                          src={preview}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : profileImage ? (
                        <img
                          src={IMG_URL +"/"+ profileImage}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={uploadImg}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      )}
                    </div>
                    <input
                      className="user_detail_upload"
                      id="formFileSm"
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                      accept="image/*"
                    />
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Full Name"
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName}
                              />
                                 {!fullName && (
                                <div className="text-danger fs-12">
                                  Please Enter Name
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Action</label>
                              <div className=" ">
                                {driverDetail?.approval_status === 0 ? (
                                  <>
                                    <span className="badge light border-0 pl-0">
                                      <button
                                        onClick={() => {
                                          driverDetail?.proof_image
                                            ? dispatch(
                                                updateDriverProfile({
                                                  driverId: driverDetail?._id,
                                                  approval_status: 1,
                                                })
                                              )
                                            : toast.warning(
                                                "Licence info is missing"
                                              );
                                        }}
                                        className="badge border-0 badge-success"
                                      >
                                        Accept
                                      </button>
                                    </span>
                                    <span className="">
                                      <button
                                        onClick={() => {
                                          driverDetail?.proof_image
                                            ? dispatch(
                                                updateDriverProfile({
                                                  driverId: driverDetail?._id,
                                                  approval_status: 2,
                                                })
                                              )
                                            : toast.warning(
                                                "Licence info is missing"
                                              );
                                        }}
                                        className="badge border-0 badge-danger"
                                      >
                                        Reject
                                      </button>
                                    </span>
                                  </>
                                ) : (
                                  <span
                                    className={`badge light border-0 ${
                                      driverDetail?.approval_status === 1
                                        ? "badge-success"
                                        : "badge-danger"
                                    } `}
                                  >
                                    {driverDetail?.approval_status === 1
                                      ? "Approved"
                                      : driverDetail?.approval_status === 2
                                      ? "Rejected"
                                      : ""}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Phone Number</label>
                              <div className="input-group">
                              <Select
                                  value={
                                    selectedCode
                                      ? {
                                          label: selectedCode.value,
                                          value: selectedCode.value,
                                        }
                                      : null
                                  }
                                  onChange={(e) => setSelectedCode(e)}
                                  options={countryCode}
                                  isClearable={true}
                                  placeholder="+00"
                                  styles={customStyles}
                                />
                              <input
                                type="number"
                                name="phone_number"
                                className="form-control"
                                placeholder="Phone Number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                              />
                              </div>
                                  {!phoneNumber && (
                                <div className="text-danger fs-12">
                                  Please Enter Phone Number
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                                {!email && (
                                <div className="text-danger fs-12">
                                  Please Enter Email
                                </div>
                              )}
                            </div>
                            {/* <div className="form-group mb-3 col-md-6">
                              <label>Status</label>
                              <select
                                defaultValue={"option"}
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="option" disabled hidden>
                                  {status == 1
                                    ? "Active"
                                    : status == 0
                                    ? "Suspended"
                                    : "Select.."}
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Suspended</option>
                              </select>
                            </div> */}
                          </div>
                          <div>
                            <button
                              onClick={() => handleSubmit()}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="heading mb-0">ID Info</h4>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4">
                      <LightGallery
                        // onInit={onInit}
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                        elementClassNames="w-100 sp4"
                      >
                        <img
                          src={
                            driverDetail?.proof_image
                              ? IMG_URL +"/"+ driverDetail?.proof_image
                              : LicenceImg
                          }
                          alt=""
                          className="w-100"
                          style={{ height: "100px" }}
                        />
                      </LightGallery>
                      {/* <img
                        src={
                          driverDetail?.license_photo_front
                            ? IMG_URL + "/" + driverDetail?.license_photo_front
                            : LicenceImg
                        }
                        alt=""
                        className="w-100"
                        style={{ height: "100px" }}
                      /> */}
                    </div>
                    <div className="col-xl-8 col-lg-8">
                      <div className="row">
                        <div className="col-xl-10 col-lg-6">
                          <div className="c-card-details">
                            <ul>
                              <li>
                                <h6>ID Name :</h6>
                                <span className="ms-1">
                                  {driverDetail?.id_proof_title
                                    ? driverDetail?.id_proof_title
                                    : "N/A"}
                                </span>
                              </li>
                              {/* <li>
                                <h6>Issued Date :</h6>
                                <span className="ms-1">
                                  {driverDetail?.issued_date
                                    ? driverDetail?.issued_date
                                    : "N/A"}
                                </span>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="heading mb-0">Login information</h4>
                </div>
                <div className="card-body pt-0">
                  <div className="c-work ">
                    {/* <LightGallery
                      speed={500}
                      plugins={[lgThumbnail, lgZoom]}
                      elementClassNames="row sp4"
                    >
                      {galleryBlog?.map((item, index) => (
                        <div
                          data-src={item?.image}
                          className="col-lg-2 pointerUser"
                          key={index}
                        >
                          <img
                            className="px-1 py-1 img-fluid rounded"
                            src={item?.image}
                            style={{ width: "100%", height: "80px" }}
                            alt="gallery"
                          />
                        </div>
                      ))}
                    </LightGallery> */}
                  </div>
                  <ul className="c-primium vehicle-info">
                    <li>
                      <div className="user_additional_info_inner">
                      </div>
                      <div className="user_additional_info_content">
                        <p>Last Login</p>
                        <h2>
                          {moment(driverDetail?.lastLogin).format(
                            "Do MMMM YYYY"
                          )}
                        </h2>
                      </div>
                    </li>
                    <li>
                      <div className="user_additional_info_inner">
                      </div>
                      <div className="user_additional_info_content">
                        <p>Registered Date</p>
                        <h2>
                          {moment(driverDetail?.createdAt).format(
                            "Do MMMM YYYY"
                          )}
                        </h2>
                      </div>
                    </li>
                    <li>
                      <div className="user_additional_info_inner">
                      </div>
                      <div className="user_additional_info_content">
                        <p>Device Type</p>
                        <h2>
                          {driverDetail?.device_type == 1 ? "iOS" : "Android"}
                        </h2>
                      </div>
                    </li>
                    <li>
                      <div className="user_additional_info_inner">
                      </div>
                      <div className="user_additional_info_content">
                        <p>Device Model</p>
                        <h2>
                          {driverDetail?.device_model
                            ? driverDetail?.device_model
                            : "N/A"}
                        </h2>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Other Information</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <ul className="user_additional_info">
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <PermIdentityIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Gender</p>
                          <h2>
                            {driverDetail?.gender == 1
                              ? "Male"
                              : driverDetail?.gender == 0
                              ? "Female"
                              : "N/A"}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <CalendarMonthIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Date of Birth</p>
                          <h2>
                            {driverDetail?.dob ? driverDetail?.dob : "N/A"}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          {/* <img  src={profile} alt="" /> */}
                          <div className="theme-change-btn">
                            <SpaceDashboardIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Login type</p>
                          <h2>General</h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <LocationOnIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Location</p>
                          <h2>
                            {" "}
                            {driverDetail?.address
                              ? driverDetail?.address
                              : "N/A"}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <TravelExploreIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p className="p_fixed ">Nationality</p>
                          {driverDetail?.nationality?.length > 0
                            ? driverDetail?.nationality.map((item) => {
                                return <h2>{item}</h2>;
                              })
                            :<h2>N/A</h2>}
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <LanguageIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Languages</p>
                          {driverDetail?.languages?.length > 0
                            ? driverDetail?.languages.map((item) => {
                                return <h2>{item}</h2>;
                              })
                            :<h2>N/A</h2>}
                        </div>
                      </li>
                      {/* <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <WorkspacePremiumIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Experience</p>
                          <h2>
                            {driverDetail?.year_experience
                              ? driverDetail?.year_experience > 1
                                ? driverDetail?.year_experience + " " + "Years"
                                : driverDetail?.year_experience + " " + "Year"
                              : ""}{" "}
                            {driverDetail?.month_experience
                              ? driverDetail?.month_experience > 1
                                ? driverDetail?.month_experience +
                                  " " +
                                  "Months"
                                : driverDetail?.month_experience + " " + "Month"
                              : ""}
                          </h2>
                        </div>
                      </li>
                   */}
                   <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <InfoIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>About Us</p>
                          <span>
                            {driverDetail?.about_me
                              ? driverDetail?.about_me
                              : <h2>N/A</h2>}
                          </span>
                           
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <StarBorderIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Reviews</p>
                          <h2>
                            {driverDetail?.overall_rating
                              ? driverDetail?.overall_rating
                              : <h2>N/A</h2>}
                          </h2>
                        </div>
                      </li>
                    
                

                      {/* <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <LocationOnIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                    
                          <button
                            onClick={() => setViewTrack(true)}
                            className="btn btn-primary"
                          >
                            Track
                          </button>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Social</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <ul className="social_content">
                      <li>
                        <div className="social_content_img">
                          {/* <img  src={profile} alt="" /> */}
                          <div className="theme-change-btn">
                            <GoogleIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>
                            {driverDetail?.social_platform == 1
                              ? "Google"
                              : "N/A"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <div className="theme-change-btn">
                            <FacebookIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <div className="theme-change-btn">
                            <AppleIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>
                            {driverDetail?.social_platform == 2
                              ? "Apple"
                              : "N/A"}
                          </p>
                        </div>
                      </li>
                      {/* <li>
                        <div className="social_content_img">
                          <div className="theme-change-btn">
                            <FacebookIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <div className="theme-change-btn">
                            <GitHubIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-6 col-lg-12">
                  <div className="card colum-height">
                    <div className="card-header">
                      <h4 className="card-title">Deactivate Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form d-flex">
                        <div className="form-check switch custom-checkbox mb-3  ">
                          {
                            <Switch
                              checked={switchStatus}
                              onChange={(e) =>
                                handleDeactivateAcc(e.target.checked)
                              }
                            />
                          }
                        </div>
                        <h6 className="ms-2">
                          You will not be able to receive messages,
                          notifications for up to 24 hours.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-8">
                  <div className="card colum-height">
                    <div className="card-header">
                      <h4 className="card-title">Delete Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <h6>
                          Once you delete the account, there is no going back.
                          Please be certain.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-12">
                            <div className="form-check custom-checkbox mb-3 checkbox-success delete_account">
                              <button
                                className="btn btn-danger mb-2 "
                                onClick={() => {
                                  setDeleteId(driverDetail?._id);
                                  setViewVal(true);
                                }}
                              >
                                Delete my account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card colum-height">
                <div className="card-header">
                  <h4 className="card-title">Reset Password</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    {/* <form> */}
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>New Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </div>
                    </div>
                    <div className="row"></div>
                    <div className="form-group">
                      <div className=" "></div>
                    </div>
                    <button
                      onClick={() => {
                        handleChangePass();
                      }}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DriverDetail;
