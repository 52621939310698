import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import { getallRequests, requestList } from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
const Requests = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allRequests = useSelector((state) => state?.adminReducer?.requestList);
  console.log(allRequests,"allRequests");
  const totalRequest = useSelector(
    (state) => state?.adminReducer?.totalRequest
  );
  const lastIndex = page * limit;
  const npage = Math.ceil(totalRequest / limit);
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  useEffect(() => {
    dispatch(requestList(page, limit, search));
  }, [page, limit, search]);

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Request"
        pageTitle={"Request"}
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h className="heading mb-0">Request</h>
                    <Search setSearch={setSearch} setPage={setPage} />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          {/* <th>S.N.</th> */}
                          <th>Booking Id</th>
                          <th>Customer Name</th>
                          <th>Category</th>
                          <th>Type</th>
                          <th>Date & Time</th>
                          <th>Amount</th>
                          <th>Driver</th>
                          {/* <th>Reviews</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {allRequests?.length>0?
                          allRequests?.map((item, index) => (
                          <tr key={index}>
                       
                            <td>
                              <span>{item?.booking_id?item?.booking_id:"N/A"}</span>
                            </td>
                            <td>
                              <div className="products">
                                <img
                                  src={
                                    item?.booking_photo
                                      ? IMG_URL + item?.booking_photo
                                      : Placholder
                                  }
                                  className="avatar avatar-md"
                                  alt=""
                                />
                                <div
                                  className="pointerUser"
                                  onClick={() =>
                                    navigate(`/request-detail/${item._id}`)
                                  }
                                >
                                  <h6>{item?.userId?.full_name}</h6>
                                  {/* <span>Customer</span> */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="text-primary ">
                                {item?.booking_category == 1
                                  ? "Construction & Building"
                                  : item?.booking_category == 2
                                  ? "Home Furniture & Decor"
                                  : item?.booking_category == 3
                                  ? "Other Materials"
                                  : "N/A"}
                              </span>
                            </td>
                            <td>
                              <span>
                                {item?.booking_type?item?.booking_type:"N/A"}
                              </span>
                            </td>
                            <td>
                              <h6 className="m-0">
                                {moment(item?.createdAt).format("Do MMMM YYYY")}
                              </h6>
                              <span>
                                {moment(item?.createdAt).format("hh:mm")}
                              </span>
                            </td>
                            <td>
                              <span>{item?.amount ? item?.amount : "N/A"}</span>
                            </td>
                            <td>
                              <span>{item?.driverId?.full_name?item?.driverId?.full_name:"N/A"}</span>
                            </td>
                          
                          </tr>
                        )):<tr>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          NOT YET
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                        </tr>}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {totalRequest < lastIndex ? totalRequest : lastIndex} of{" "}
                        {totalRequest} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Requests;
