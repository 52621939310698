import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
/// Image
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  updateUserProfile,
  viewUserDetail,
} from "../../store/actions/AdminAction";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import DeleteModal from "../components/modals/DeleteModal";
import Switch from "@mui/material/Switch";
import countriesWcode from "../constant/CountriesWcode";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LicenceImg from "../../images/profile/license-placeholder.png";
import Select from "react-select";
import countriesCodeSelect from "../constant/CountryCodeSelect";
import CustomStyles from "../../utils/SelectStyle";
const UserDetail = () => {
  const countryCode = countriesCodeSelect;
  const country = countriesWcode;
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [profileImage, setProfileImage] = useState("");
  const [preview, setPreview] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [viewVal, setViewVal] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [activate, setActivate] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [driverId, setDriverId] = useState();
  const [selectedCode, setSelectedCode] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state?.adminReducer?.userDetail);
  const themeMode = useSelector((state) => state.adminReducer.themeMode);
  const customStyles = CustomStyles(themeMode);
  useEffect(() => {
    dispatch(viewUserDetail(id));
  }, []);
  const galleryBlog = [
    {
      image: userDetail?.company_photos[0]
        ? IMG_URL + userDetail?.company_photos[0]
        : LicenceImg,
    },
    {
      image: userDetail?.company_photos[1]
        ? IMG_URL + userDetail?.company_photos[1]
        : LicenceImg,
    },
    {
      image: userDetail?.company_photos[2]
        ? IMG_URL + userDetail?.company_photos[2]
        : LicenceImg,
    },
    {
      image: userDetail?.company_photos[3]
        ? IMG_URL + userDetail?.company_photos[3]
        : LicenceImg,
    },
    {
      image: userDetail?.company_photos[4]
        ? IMG_URL + userDetail?.company_photos[4]
        : LicenceImg,
    },
    {
      image: userDetail?.company_photos[5]
        ? IMG_URL + userDetail?.company_photos[5]
        : LicenceImg,
    },
  ];
  useEffect(() => {
    if (userDetail) {
      setProfileImage(userDetail?.profile_image);
      setFullName(userDetail?.full_name);
      setEmail(userDetail?.email);
      setPhoneNumber(userDetail?.phone_number);
      setStatus(userDetail?.status);
      setSwitchStatus(userDetail?.status == 0 ? true : false);
      setSelectedCode({
        label: userDetail?.country_code,
        value: userDetail?.country_code,
      });
    }
  }, [userDetail]);
  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("userId", userDetail?._id);
    profileImage && formData.append("profile_image", profileImage);
    fullName && formData.append("full_name", fullName);
    email && formData.append("email", email);
    phoneNumber && formData.append("phone_number", phoneNumber);
    status && formData.append("status", status);
    if (fullName && phoneNumber && email) {
    dispatch(updateUserProfile(formData));
    }
  };
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangePass = () => {
    const dataVal = {
      userId: userDetail?._id,
      password: password,
    };
    if (password) {
      if (password == confirmPassword) {
        dispatch(changePassword(dataVal));
      } else {
        toast.error("Password dosen't match");
      }
    }
  };

  const handleDeactivateAcc = (checked) => {
    if (checked == true) {
      setDriverId(userDetail?._id);
      setActivate(true);
    } else if (checked == false) {
      setDriverId(userDetail?._id);
      setDeactivate(true);
    }
  };
  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Company Details & Edit"
          pageTitle={"Company Details"}
          parentTitle={"Home"}
        />
        {deleteId && (
          <DeleteModal
            viewVal={viewVal}
            setShowVal={setViewVal}
            modalName={"userAccount"}
            id={deleteId}
          />
        )}
        {deactivate == true && (
          <DeleteModal
            viewVal={deactivate}
            setShowVal={setDeactivate}
            modalName={"deactiveUser"}
            userId={driverId}
          />
        )}
        {activate == true && (
          <DeleteModal
            viewVal={activate}
            setShowVal={setActivate}
            modalName={"activateUser"}
            userId={driverId}
          />
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info">
                    <div className="user_detail_img_inner">
                      {preview ? (
                        <img
                          src={preview}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : profileImage ? (
                        <img
                          src={IMG_URL + profileImage}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={uploadImg}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      )}
                    </div>
                    <input
                      className="user_detail_upload"
                      id="formFileSm"
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                      accept="image/*"
                    />
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Name"
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName}
                              />
                                {!fullName && (
                                <div className="text-danger fs-12">
                                  Please Enter Name
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                                {!email && (
                                <div className="text-danger fs-12">
                                  Please Enter Email
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Phone Number</label>
                              <div className="input-group">
                              <Select
                                  value={
                                    selectedCode
                                      ? {
                                          label: selectedCode.value,
                                          value: selectedCode.value,
                                        }
                                      : null
                                  }
                                  onChange={(e) => setSelectedCode(e)}
                                  options={countryCode}
                                  isClearable={true}
                                  placeholder="+00"
                                  styles={customStyles}
                                />
                              <input
                                type="number"
                                name="phone_number"
                                className="form-control"
                                placeholder="Phone Number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                              />
                               </div>
                              {!phoneNumber && (
                                <div className="text-danger fs-12">
                                  Please Enter Phone Number
                                </div>
                              )}
                            </div>
                            {/* <div className="form-group mb-3 col-md-6">
                              <label>Status</label>
                              <select
                                defaultValue={"option"}
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="option" disabled hidden>
                                  {status == 1
                                    ? "Active"
                                    : status == 0
                                    ? "Suspended"
                                    : "Select.."}
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Suspended</option>
                              </select>
                            </div> */}
                          </div>
                          <div>
                            <button
                              onClick={() => handleSubmit()}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Other Information</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <ul className="user_additional_info">
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <AirplayIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Last Login</p>
                          <h2>
                            {moment(userDetail?.lastLogin).format(
                              "Do MMMM YYYY"
                            )}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          {/* <img  src={profile} alt="" /> */}
                          <div className="theme-change-btn">
                            <SpaceDashboardIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Login type</p>
                          <h2>{userDetail?.social_platform == 0
                              ? "Facebook"
                              : userDetail?.social_platform == 1
                              ? "Google"
                              : userDetail?.social_platform == 2
                              ? "Apple"
                              : "General"}</h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <CalendarMonthIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Registered Date</p>
                          <h2>
                            {moment(userDetail?.createdAt).format(
                              "Do MMMM YYYY"
                            )}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <CalendarMonthIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Company started Before</p>
                          <h2>

                            {
                              userDetail?.company_start_year != 0 && userDetail?.company_start_month != 0 ?
                               
                             ((parseInt(new Date().getFullYear()) - parseInt(userDetail?.company_start_year)) 
                             + " " + "Years" + " " +
                             (12 - parseInt(userDetail?.company_start_month)) 
                             + " " + "Months")  : "N/A"
                            }
                            {/* {userDetail?.company_start_year
                              ? userDetail?.company_start_year > 1
                                ? userDetail?.company_start_year + " " + "Years"
                                : userDetail?.company_start_year + " " + "Year"
                              : ""}{" "}
                            {userDetail?.company_start_month
                              ? userDetail?.company_start_month > 1
                                ? userDetail?.company_start_month +
                                  " " +
                                  "Months"
                                : userDetail?.company_start_month +
                                  " " +
                                  "Month"
                              : ""} */}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <PhoneAndroidIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Device Type</p>
                          <h2>
                            {userDetail?.device_type == 1 ? "iOS" : "Android"}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <ReportGmailerrorredIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Device Model</p>
                          <h2>{userDetail?.device_model?userDetail?.device_model:"N/A"}</h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <div className="theme-change-btn">
                            <LocationOnIcon />
                          </div>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Location</p>
                          <h2> {userDetail?.country_code?
                              (userDetail?.country_code,
                                country[userDetail?.country_code]):"N/A"
                            }</h2>
                         
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="heading mb-0">Company information</h4>
                </div>
                <div className="card-body pt-0">
                  <div className="c-work ">
                    <LightGallery
                      speed={500}
                      plugins={[lgThumbnail, lgZoom]}
                      elementClassNames="row sp4"
                    >
                      {galleryBlog?.map((item, index) => (
                        <div
                          data-src={item?.image}
                          className="col-lg-2 pointerUser"
                          key={index}
                        >
                          <img
                            className="px-1 py-1 img-fluid rounded"
                            src={item?.image}
                            style={{ width: "100%", height: "80px" }}
                            alt="gallery"
                          />
                        </div>
                      ))}
                    </LightGallery>
                  </div>
                  <ul className="c-primium vehicle-info">
                    <li>
                      <h6>Company Website</h6>
                      <span>
                        {userDetail?.company_website
                          ? userDetail?.company_website
                          : "N/A"}
                      </span>
                    </li>
                    <li>
                      <h6>Company Size</h6>
                      <span>
                        {userDetail?.company_size
                          ? userDetail?.company_size
                          : "N/A"}
                      </span>
                    </li>
                    <li>
                        <div className="">
                          <h6>About Us</h6>
                          <span>
                            {userDetail?.about_us ? userDetail?.about_us : "N/A"}
                          </span>
                        </div>
                      </li>
                      <li>
                      <h6>Vehicle Type</h6>
                      {userDetail?.truck_type?.length>0?
                        userDetail?.truck_type.map((item)=>{
                          return  <span>
                        {item}
                      </span>
                        }):"N/A"
                      }
                     
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Social</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <ul className="social_content">
                      <li>
                        <div className="social_content_img">
                          {/* <img  src={profile} alt="" /> */}
                          <div className="theme-change-btn">
                            <GoogleIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>
                            {userDetail?.social_platform == 1
                              ? "Google"
                              : "N/A"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <div className="theme-change-btn">
                            <FacebookIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <div className="theme-change-btn">
                            <AppleIcon />
                          </div>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>
                            {userDetail?.social_platform == 2 ? "Apple" : "N/A"}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
            <div className="card ">
                    <div className="card-header">
                      <h4 className="card-title">Reset Password</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label>New Password</label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label>Confirm Password</label>
                          <input
                            type="password"
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                          />
                        </div>
                      </div>

                      <button
                        onClick={() => {
                          handleChangePass();
                        }}
                        className="btn btn-primary "
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
            
            <div className="col-xl-6 col-lg-12">
                  <div className="card colum-height">
                    <div className="card-header">
                      <h4 className="card-title">Deactivate Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form d-flex">
                        <div className="form-check switch custom-checkbox mb-3 checkbox-success">
                          <Switch
                            checked={switchStatus}
                            onChange={(e) =>
                              handleDeactivateAcc(e.target.checked)
                            }
                          />
                        </div>
                        <h6 className="ms-2">
                          You will not be able to receive messages,
                          notifications for up to 24 hours.
                        </h6>
                      </div>
                    </div>
                  </div>
                  </div>
                <div className="col-xl-6 col-lg-12">
               
                  <div className="card colum-height">
                    <div className="card-header">
                      <h4 className="card-title">Delete Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="row"></div>
                      <div className="basic-form">
                        <h6>
                          Once you delete the account, there is no going back.
                          Please be certain.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-12">
                            <div className="form-check custom-checkbox mb-3 checkbox-success delete_account">
                              <button
                                className="btn btn-danger "
                                onClick={() => {
                                  setDeleteId(userDetail?._id);
                                  setViewVal(true);
                                }}
                              >
                                Delete my account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            
            </div>
          
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserDetail;
